@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}